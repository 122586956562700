<template>
  <div class="g_page_box">
    <div class="g_main_content">
      <g-loading :loading="loading" />
      <div class="health_card">
        <van-skeleton title :row="10" :loading="loading">
          <div>
            <div class="health_title">
              {{ data.report_name }}
            </div>
            <div class="headth_box">
              <div class="health_sub">
                申请时间：{{ format_date(data.apply_date) }}
              </div>
              <div class="health_sub">
                检查时间：{{ format_date(data.exame_date) }}
              </div>
              <div class="health_sub wp100">
                报告时间：{{ format_date(data.report_date) }}
              </div>
              <div class="health_sub">报告人：{{ data.report_doctor }}</div>
              <div class="health_sub">申请人：{{ data.apply_doctor }}</div>
            </div>

            <div class="health_bottom">
              <div class="health_description" v-html="data.result"></div>
            </div>
          </div>
        </van-skeleton>
      </div>
    </div>
  </div>
</template>

<script>
import { format_date } from "../../utils/format";
import { get_exam } from "./servive";
export default {
  data() {
    return {
      data: {},
      loading: true,
      id: null,
    };
  },
  computed: {},
  components: {},
  watch: {},
  created() {
    this.id = this.$route.query.id;
    this.get_data(this.id);
  },
  methods: {
    format_date,
    async get_data(id) {
      this.loading = true;
      try {
        const { data } = await get_exam(id);
        this.data = data;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.g_main_content {
  background: #f2f7f8;
}

.health_card {
  margin: 14px 10px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.02);
  padding: 17px 14px;
  color: #292929;
  border-radius: 10px;
  font-size: 14px;
  line-height: 20px;
}

.health_title {
  font-weight: 500;
  font-size: 16px;
}

.headth_box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding-top: 5px;
}

.health_sub {
  width: 50%;
  padding-top: 7px;

  &.wp100 {
    width: 100%;
  }
}

.health_bottom {
  padding-top: 18px;
}

.health_description {
  line-height: 18px;
}
</style>
