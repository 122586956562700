import api from '../../utils/api'

// 授权
export async function get_exam (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         exame_name: '盆腔超声',
  //         apply_date: 1623396251000,
  //         exame_date: 1623396251000,
  //         report_date: 1623396251000,
  //         report_doctor: '姜粒',
  //         exame_doctor: '蒋欣',
  //         apply_doctor: '',
  //         context: '超声表现：<br>(1)双侧卵巢增大，可为正常的2-3倍，主要是厚度增加，最大径线可达50mm。<br>(2)卵泡包膜增厚，声像图显示卵巢轮廓清晰，表面回声增强，周围可出现一薄强回声环。<br>(3)经阴道超声卵巢内可见到≥12个卵泡(经腹≥10个卵泡) ,直径在2-8mm。<br>(4)髓质水肿，表现为卵巢中央髓质部见一强回声区。正常情况下声像图不显示卵巢髓质回声。<br>(5)子宫正常大小或稍大。'
  //       }
  //     })
  //   }, 1000)
  // })
  return api.get(`/api/v1/mp/healthy/document/examine/detail`, {params: {id: payload}})
  //return api.get(`http://112.124.59.193:5000/mock/393/api/v1/mp/robot/examine/${payload}`)
}

